@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  font-family: 'Poppins', sans-serif;

}

.loginContainer{
    height: 100vh;
    padding: 4em;
   
}

.loginForm{
    box-shadow: 1px 5px 10px 5px #cecece;
    padding: 3em 4em 5em 4em ;
    width: 55%;
    margin: 1em auto;
    border-radius: 10px;  
}

.loginContainer img{
    display: block;
    margin: 2em auto 0 auto;
    width: 25%;
  
}

.loginContainer h2{
    text-align: center;
    margin:.1em auto 1.5em auto;
    color: #201B50;
    font-weight: 500;
    font-size: 1.8rem;
}

.loginContainer p{
    width: 85%;
    text-align: center;
    margin: 1em auto;
    color: #c9c9c9;
    font-size: .9rem;
}

.loginFormGroup{
    margin: 1em;
    width: 85%;
    margin: auto;
    color: #201B50;
}
.loginFormInput{
    border: 2px solid #f4f2f2f2;

}
.loginFormInput::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #858585;
    opacity: 1; /* Firefox */
  }
.loginFormInput:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #858585;
    opacity: 1; /* Firefox */
  }
.loginFormInput::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #858585;
    opacity: 1; /* Firefox */
  }
.loginFormButton{
    width: 100%;
    background-color: #4285F4;
    border: none;
    padding: .6em .8em;
}
#forgetPass{
    width: 100%;
    text-align: right;
    text-decoration: underline;
}

@media screen and (max-width: 1200px){
    .loginContainer{
        padding: 2em;
       
    }
    .loginForm{
        box-shadow: 1px 5px 10px 5px #cecece;
        padding: 0;
        width: 75%;
        margin: 1em auto;
        border-radius: 10px;  
    }
    
    
}
@media screen and (max-width: 500px){
    .loginContainer{
        padding: 1em;
       
    }
    .loginForm{
        box-shadow: 1px 5px 10px 5px #cecece;
        padding: 1rem;
        width: 90%;
        margin:  auto;
        border-radius: 10px;  
    }
    .loginFormGroup{
        width: 90%;

    }
    .loginContainer h2{
        text-align: center;
        margin:.1em auto .1em auto;
        color: #201B50;
        font-weight: 600;
        font-size: 1.25rem;
    }
    
    .loginContainer p{
        width: 85%;
        text-align: center;
        margin: 1em auto;
        color: #c9c9c9;
        font-size: .8rem;
    }
    
    
}

#sideBar {
  min-height: calc(100vh );
  position: absolute;
  /* box-shadow: 2px 2px 5px #111; */
  padding: 1rem;
  z-index: 1;
  transition: 0.5s;
  overflow-x: hidden; /*Disable horizontal scroll*/
  background: #fff;
 
 
  /* box-shadow: 5px 0 2px -2px #ececec; */
  box-shadow:rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 8%) 0px 0px 4px;
}

.sideBarArrow {
  position: fixed;
  cursor: pointer;
  top: 5px;
  z-index: 2;
  color: rgb(4, 27, 48);
  border-radius: 10px;
  padding: 0.1em;

  width: -webkit-fit-content;

  width: -moz-fit-content;

  width: fit-content;
  font-size: 1.5em;
  display: none;
  /* top: 420px; */
}
.text-logo {
  color: #594bdb;
  font-weight: 600;
}

#sideBar h5 {
  border-radius: 10px;
  background: #4285f4;
  color: White;
  padding: 1rem;
  font-size: 1rem;
}
#sideBar h4 { 
   
  font-size: 1rem;
  padding:8px;
}

.sideFooter {
  margin: auto;
  position: fixed;
  bottom: 10px;
  left: 20px;
}
.text-link{
  text-decoration: none;
  font-size: 14px;
  color: #3C3C3c;

}
.text-link:hover{
  /* font-weight: 500; */
  color: #4285f4;
}
.text-link:active{
  color: #594bdb;
}
.text-link:focus{
  font-weight: 600;
  color: #4285f4;
}
.list-tems{
  border: none;
}
.fontIcon{
  margin-right: .5rem;
}

/** Changing the accordion button*/

.accordion-flush .accordion-item .accordion-button {
  border-radius: 10px !important;
  padding: .5em;
  border: none;
}
.accordion-button:focus {
  background:none;
}
  
.sideBarHeader-list-header h4{
  font-size: 1rem !important;
}
/* @media screen and (max-width: 1900px) {
  #sideBar {
    width: 65%;
  }
} */

@media screen and (max-width: 1200px) {
  #sideBar {
    width: 100%;
  }
}

@media screen and (max-width: 775px) {
  #sideBar {
    display: none;
  }
  .sideBarArrow {
    display: block;
  }
}

.appbar{
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 4px;
    justify-content: flex-end;
}

.appbar .appbarLink p,
.appbar img{
    display: block;
    font-size: .9rem;
    padding: 1rem .8rem;
    color: #201B50;
    margin: .1rem 0;
}
#appBarLogo{
    height: 40px;
    width: 40px;
    padding: 0;
}

.appbar .textIcons{
    color: #4285F4;
}
#sidebarIcon{
    display: none;
}
#sideBarHeader{
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
  }
  .sideHeader{
      margin-left: 20px;
  }
#sideText{
      font-size: 10px;
      line-height: 16px;
  }
  .text-logo {
    color: #594bdb;
    font-weight: 600;
  }
  
@media screen and (max-width: 600px) {
    .appbar{
        box-shadow: 2px 2px 10px rgb(207, 206, 206);
        justify-content: center;
    }
    #sidebarIcon{
        display: block;
        color: #4285F4;
        font-size: 2rem;
        margin: 0 1rem;

      
    }
    .appbar p,
    .appbar img{
    font-size: 1rem;
    padding: .5rem .7rem;
    color: #201B50;
    margin: .1rem 0;
}
  }
.districtInfo h3{
    color: #201B50;
}
.table-head{
    color: #201B50;
    font-weight: 500;
}
.table-row:hover{
    background: #e2e0e0;
   
    transition: .5s;
}
.boxContainer,.districtTableContainer{
    max-width: 1450px;
  
    box-shadow: rgb(20 21 26 / 4%) 0px 1px 2px, rgb(71 77 87 / 4%) 0px 3px 6px, rgb(20 21 26 / 10%) 0px 0px 1px;
    padding:  5rem;
    margin:0 auto 4em auto;
    /* border-radius: 10px; */
}
.linkText{
    text-decoration: none;
}
.dashboardCard h4{
    width: 100px;
    height: 80px;
    background: #4285F4;
    margin: .5em;
    border-radius: 10px;
    color: #fff;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: 1px 1px 5px #707070;
    cursor: pointer;
    text-decoration: none;
}
.table-head{
    
}
