.linkText{
    text-decoration: none;
}
.dashboardCard h4{
    width: 100px;
    height: 80px;
    background: #4285F4;
    margin: .5em;
    border-radius: 10px;
    color: #fff;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: 1px 1px 5px #707070;
    cursor: pointer;
    text-decoration: none;
}