.loginContainer{
    height: 100vh;
    padding: 4em;
   
}

.loginForm{
    box-shadow: 1px 5px 10px 5px #cecece;
    padding: 3em 4em 5em 4em ;
    width: 55%;
    margin: 1em auto;
    border-radius: 10px;  
}

.loginContainer img{
    display: block;
    margin: 2em auto 0 auto;
    width: 25%;
  
}

.loginContainer h2{
    text-align: center;
    margin:.1em auto 1.5em auto;
    color: #201B50;
    font-weight: 500;
    font-size: 1.8rem;
}

.loginContainer p{
    width: 85%;
    text-align: center;
    margin: 1em auto;
    color: #c9c9c9;
    font-size: .9rem;
}

.loginFormGroup{
    margin: 1em;
    width: 85%;
    margin: auto;
    color: #201B50;
}
.loginFormInput{
    border: 2px solid #f4f2f2f2;

}
.loginFormInput::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #858585;
    opacity: 1; /* Firefox */
  }
.loginFormButton{
    width: 100%;
    background-color: #4285F4;
    border: none;
    padding: .6em .8em;
}
#forgetPass{
    width: 100%;
    text-align: right;
    text-decoration: underline;
}

@media screen and (max-width: 1200px){
    .loginContainer{
        padding: 2em;
       
    }
    .loginForm{
        box-shadow: 1px 5px 10px 5px #cecece;
        padding: 0;
        width: 75%;
        margin: 1em auto;
        border-radius: 10px;  
    }
    
    
}
@media screen and (max-width: 500px){
    .loginContainer{
        padding: 1em;
       
    }
    .loginForm{
        box-shadow: 1px 5px 10px 5px #cecece;
        padding: 1rem;
        width: 90%;
        margin:  auto;
        border-radius: 10px;  
    }
    .loginFormGroup{
        width: 90%;

    }
    .loginContainer h2{
        text-align: center;
        margin:.1em auto .1em auto;
        color: #201B50;
        font-weight: 600;
        font-size: 1.25rem;
    }
    
    .loginContainer p{
        width: 85%;
        text-align: center;
        margin: 1em auto;
        color: #c9c9c9;
        font-size: .8rem;
    }
    
    
}
