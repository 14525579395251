.districtInfo h3{
    color: #201B50;
}
.table-head{
    color: #201B50;
    font-weight: 500;
}
.table-row:hover{
    background: #e2e0e0;
   
    transition: .5s;
}
.boxContainer,.districtTableContainer{
    max-width: 1450px;
  
    box-shadow: rgb(20 21 26 / 4%) 0px 1px 2px, rgb(71 77 87 / 4%) 0px 3px 6px, rgb(20 21 26 / 10%) 0px 0px 1px;
    padding:  5rem;
    margin:0 auto 4em auto;
    /* border-radius: 10px; */
}