.appbar{
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 4px;
    justify-content: flex-end;
}

.appbar .appbarLink p,
.appbar img{
    display: block;
    font-size: .9rem;
    padding: 1rem .8rem;
    color: #201B50;
    margin: .1rem 0;
}
#appBarLogo{
    height: 40px;
    width: 40px;
    padding: 0;
}

.appbar .textIcons{
    color: #4285F4;
}
#sidebarIcon{
    display: none;
}
#sideBarHeader{
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
  }
  .sideHeader{
      margin-left: 20px;
  }
#sideText{
      font-size: 10px;
      line-height: 16px;
  }
  .text-logo {
    color: #594bdb;
    font-weight: 600;
  }
  
@media screen and (max-width: 600px) {
    .appbar{
        box-shadow: 2px 2px 10px rgb(207, 206, 206);
        justify-content: center;
    }
    #sidebarIcon{
        display: block;
        color: #4285F4;
        font-size: 2rem;
        margin: 0 1rem;

      
    }
    .appbar p,
    .appbar img{
    font-size: 1rem;
    padding: .5rem .7rem;
    color: #201B50;
    margin: .1rem 0;
}
  }