#sideBar {
  min-height: calc(100vh );
  position: absolute;
  /* box-shadow: 2px 2px 5px #111; */
  padding: 1rem;
  z-index: 1;
  transition: 0.5s;
  overflow-x: hidden; /*Disable horizontal scroll*/
  background: #fff;
 
 
  /* box-shadow: 5px 0 2px -2px #ececec; */
  box-shadow:rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 8%) 0px 0px 4px;
}

.sideBarArrow {
  position: fixed;
  cursor: pointer;
  top: 5px;
  z-index: 2;
  color: rgb(4, 27, 48);
  border-radius: 10px;
  padding: 0.1em;

  width: fit-content;
  font-size: 1.5em;
  display: none;
  /* top: 420px; */
}
.text-logo {
  color: #594bdb;
  font-weight: 600;
}

#sideBar h5 {
  border-radius: 10px;
  background: #4285f4;
  color: White;
  padding: 1rem;
  font-size: 1rem;
}
#sideBar h4 { 
   
  font-size: 1rem;
  padding:8px;
}

.sideFooter {
  margin: auto;
  position: fixed;
  bottom: 10px;
  left: 20px;
}
.text-link{
  text-decoration: none;
  font-size: 14px;
  color: #3C3C3c;

}
.text-link:hover{
  /* font-weight: 500; */
  color: #4285f4;
}
.text-link:active{
  color: #594bdb;
}
.text-link:focus{
  font-weight: 600;
  color: #4285f4;
}
.list-tems{
  border: none;
}
.fontIcon{
  margin-right: .5rem;
}

/** Changing the accordion button*/

.accordion-flush .accordion-item .accordion-button {
  border-radius: 10px !important;
  padding: .5em;
  border: none;
}
.accordion-button:focus {
  background:none;
}
  
.sideBarHeader-list-header h4{
  font-size: 1rem !important;
}
/* @media screen and (max-width: 1900px) {
  #sideBar {
    width: 65%;
  }
} */

@media screen and (max-width: 1200px) {
  #sideBar {
    width: 100%;
  }
}

@media screen and (max-width: 775px) {
  #sideBar {
    display: none;
  }
  .sideBarArrow {
    display: block;
  }
}
